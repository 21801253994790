const styles = {
  "hljs": {
    border: '1px solid #555',
    display: 'block',
    overflowX: 'auto',
    padding: '1em',
    background: '#282828',
    color: '#ebdbb2'
  },
  'hljs-subst': {
    color: '#ebdbb2'
  },
  'hljs-deletion': {
    color: '#fb4934'
  },
  'hljs-formula': {
    color: '#fb4934'
  },
  'hljs-keyword': {
    color: '#fb4934'
  },
  'hljs-link': {
    color: '#fb4934'
  },
  'hljs-selector-tag': {
    color: '#fb4934'
  },
  'hljs-built_in': {
    color: '#83a598'
  },
  'hljs-emphasis': {
    color: '#83a598',
    fontStyle: 'italic'
  },
  'hljs-name': {
    color: '#83a598'
  },
  'hljs-quote': {
    color: '#83a598'
  },
  'hljs-strong': {
    color: '#83a598',
    fontWeight: 'bold'
  },
  'hljs-title': {
    color: '#83a598'
  },
  'hljs-variable': {
    color: '#83a598'
  },
  'hljs-attr': {
    color: '#fabd2f'
  },
  'hljs-params': {
    color: '#fabd2f'
  },
  'hljs-template-tag': {
    color: '#fabd2f'
  },
  'hljs-type': {
    color: '#fabd2f'
  },
  'hljs-builtin-name': {
    color: '#8f3f71'
  },
  'hljs-doctag': {
    color: '#8f3f71'
  },
  'hljs-literal': {
    color: '#d3869b'
  },
  'hljs-number': {
    color: '#d3869b'
  },
  'hljs-code': {
    color: '#fe8019'
  },
  'hljs-meta': {
    color: '#fe8019'
  },
  'hljs-regexp': {
    color: '#fe8019'
  },
  'hljs-selector-id': {
    color: '#fe8019'
  },
  'hljs-template-variable': {
    color: '#fe8019'
  },
  'hljs-addition': {
    color: '#b8bb26'
  },
  'hljs-meta-string': {
    color: '#b8bb26'
  },
  'hljs-section': {
    color: '#b8bb26',
    fontWeight: 'bold'
  },
  'hljs-selector-attr': {
    color: '#b8bb26'
  },
  'hljs-selector-class': {
    color: '#b8bb26'
  },
  'hljs-string': {
    color: '#b8bb26'
  },
  'hljs-symbol': {
    color: '#b8bb26'
  },
  'hljs-attribute': {
    color: '#8ec07c'
  },
  'hljs-bullet': {
    color: '#8ec07c'
  },
  'hljs-class': {
    color: '#8ec07c'
  },
  'hljs-function': {
    color: '#8ec07c'
  },
  'hljs-function .hljs-keyword': {
    color: '#8ec07c'
  },
  'hljs-meta-keyword': {
    color: '#8ec07c'
  },
  'hljs-selector-pseudo': {
    color: '#8ec07c'
  },
  'hljs-tag': {
    color: '#8ec07c',
    fontWeight: 'bold'
  },
  'hljs-comment': {
    color: '#928374',
    fontStyle: 'italic'
  },
  'hljs-link_label': {
    color: '#d3869b'
  }
};

export default styles;
