/* eslint-disable max-len */
import React, { Fragment } from 'react';

export const JS = (
  <g transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)">
    <path
      d="M105,3929.3c-7.3-1.7-14.5-7.3-17.8-13.8c-1.9-3.8-2-4.7-2-12.2c0-7.3,0.2-8.5,1.8-12.1c3.8-8,10.3-13,26-19.7
		c5.3-2.3,9.8-4.8,11.6-6.3c2.8-2.5,2.9-2.8,2.9-6.5c0-3.4-0.3-4.2-2-6.1c-2.6-2.7-5.8-3.7-11.4-3.7c-7.1,0.1-12.5,3-17.3,9.4
		l-1.8,2.5l-7.8-4.5c-4.2-2.5-8-4.6-8.4-4.7c-1.1-0.4,3.8-7.3,7.8-11.1c11.4-10.3,32.9-13.1,46.8-6c11.7,5.9,17.2,18.8,13.8,32.1
		c-2.8,10.6-9.3,16.4-27.6,24.3c-12.9,5.6-16,8.7-14.9,14.7c1.6,8.3,14.4,9,19.8,1.1c1.1-1.6,2.2-2.7,2.5-2.6c0.2,0.1,3.8,2.4,8,5.1
		l7.5,4.8l-1.3,2.1c-2.1,3.3-7.2,8.2-10.3,10.1c-1.6,0.9-4.8,2.2-7.1,2.7C119.4,3930.2,109.3,3930.4,105,3929.3z"
    />
    <path
      d="M5,3880.7v-48.6h33.2h33.2v8.8v8.8H48.4H25.5v11.6v11.6h19.4h19.5l-0.2,8.7l-0.2,8.6l-19.2,0.2l-19.3,0.1v10.2v10.2h21.9
		h21.9v9.2v9.2H37.1H5V3880.7z"
    />
    <path
      d="M181.5,3910.2c-7.9-10.6-15.6-21.3-17.1-23.8c-5.3-8.7-7.4-16.7-6.8-25.7c0.9-13.9,11.9-25.7,27.7-29.7
		c5.4-1.4,17.1-1.3,22.6,0c6.8,1.8,12.8,4.9,17.2,9.2c6.8,6.4,9.9,14.2,9.9,24c0,12.7-6.3,22.8-17.4,28.1c-4.2,1.9-5.6,2.2-12.6,2.5
		c-4.9,0.2-8.8,0-10.2-0.5c-2.1-0.7-2.1-0.6,1.1,2.1c2.5,2.3,22.9,28,25.4,32.2c0.4,0.6-2.3,0.8-12.4,0.8h-13L181.5,3910.2z
		 M204,3876.5c5.6-2.9,8.7-9.9,7.1-16c-1.8-7-7.3-11.1-15-11.1c-11.3-0.1-18.6,11.4-13.5,21.1c2,3.8,3.7,5.3,8.1,6.8
		C195.1,3878.9,199.9,3878.6,204,3876.5z"
    />
  </g>
);

export const SUN_OUTLINED = (
  <Fragment>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79zM1 10.5h3v2H1zM11 .55h2V3.5h-2zm8.04 2.495l1.408 1.407-1.79 1.79-1.407-1.408zm-1.8 15.115l1.79 1.8 1.41-1.41-1.8-1.79zM20 10.5h3v2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 10c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm-1 4h2v2.95h-2zm-7.45-.96l1.41 1.41 1.79-1.8-1.41-1.41z" />
  </Fragment>
);

export const SUN_FILLED = (
  <Fragment>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M6.76 4.84l-1.8-1.79-1.41 1.41 1.79 1.79 1.42-1.41zM4 10.5H1v2h3v-2zm9-9.95h-2V3.5h2V.55zm7.45 3.91l-1.41-1.41-1.79 1.79 1.41 1.41 1.79-1.79zm-3.21 13.7l1.79 1.8 1.41-1.41-1.8-1.79-1.4 1.4zM20 10.5v2h3v-2h-3zm-8-5c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm-1 16.95h2V19.5h-2v2.95zm-7.45-3.91l1.41 1.41 1.79-1.8-1.41-1.41-1.79 1.8z" />
  </Fragment>
);

export const ICON_FACEBOOK = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
    <title>facebook</title>
    <path d="M19 6h5v-6h-5c-3.86 0-7 3.14-7 7v3h-4v6h4v16h6v-16h5l1-6h-6v-3c0-0.542 0.458-1 1-1z" />
  </svg>
);

export const ICON_TWITTER = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
    <title>twitter</title>
    <path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z" />
  </svg>
);

export const ICON_LINKEDIN = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
    <title>linkedin</title>
    <path d="M12 12h5.535v2.837h0.079c0.77-1.381 2.655-2.837 5.464-2.837 5.842 0 6.922 3.637 6.922 8.367v9.633h-5.769v-8.54c0-2.037-0.042-4.657-3.001-4.657-3.005 0-3.463 2.218-3.463 4.509v8.688h-5.767v-18z" />
    <path d="M2 12h6v18h-6v-18z" />
    <path d="M8 7c0 1.657-1.343 3-3 3s-3-1.343-3-3c0-1.657 1.343-3 3-3s3 1.343 3 3z" />
  </svg>
);

export const ICON_REDDIT = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
    <title>reddit</title>
    <path d="M8 20c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM20 20c0-1.105 0.895-2 2-2s2 0.895 2 2c0 1.105-0.895 2-2 2s-2-0.895-2-2zM20.097 24.274c0.515-0.406 1.262-0.317 1.668 0.198s0.317 1.262-0.198 1.668c-1.434 1.13-3.619 1.86-5.567 1.86s-4.133-0.73-5.567-1.86c-0.515-0.406-0.604-1.153-0.198-1.668s1.153-0.604 1.668-0.198c0.826 0.651 2.46 1.351 4.097 1.351s3.271-0.7 4.097-1.351zM32 16c0-2.209-1.791-4-4-4-1.504 0-2.812 0.83-3.495 2.057-2.056-1.125-4.561-1.851-7.29-2.019l2.387-5.36 4.569 1.319c0.411 1.167 1.522 2.004 2.83 2.004 1.657 0 3-1.343 3-3s-1.343-3-3-3c-1.142 0-2.136 0.639-2.642 1.579l-5.091-1.47c-0.57-0.164-1.173 0.116-1.414 0.658l-3.243 7.282c-2.661 0.187-5.102 0.907-7.114 2.007-0.683-1.227-1.993-2.056-3.496-2.056-2.209 0-4 1.791-4 4 0 1.635 0.981 3.039 2.387 3.659-0.252 0.751-0.387 1.535-0.387 2.341 0 5.523 6.268 10 14 10s14-4.477 14-10c0-0.806-0.134-1.589-0.387-2.34 1.405-0.62 2.387-2.025 2.387-3.66zM27 5.875c0.621 0 1.125 0.504 1.125 1.125s-0.504 1.125-1.125 1.125-1.125-0.504-1.125-1.125 0.504-1.125 1.125-1.125zM2 16c0-1.103 0.897-2 2-2 0.797 0 1.487 0.469 1.808 1.145-1.045 0.793-1.911 1.707-2.552 2.711-0.735-0.296-1.256-1.016-1.256-1.856zM16 29.625c-6.42 0-11.625-3.414-11.625-7.625s5.205-7.625 11.625-7.625c6.42 0 11.625 3.414 11.625 7.625s-5.205 7.625-11.625 7.625zM28.744 17.856c-0.641-1.003-1.507-1.918-2.552-2.711 0.321-0.676 1.011-1.145 1.808-1.145 1.103 0 2 0.897 2 2 0 0.84-0.52 1.56-1.256 1.856z" />
  </svg>
);

export const ICON_GITHUB = (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32">
    <title>github</title>
    <path d="M16 0.395c-8.836 0-16 7.163-16 16 0 7.069 4.585 13.067 10.942 15.182 0.8 0.148 1.094-0.347 1.094-0.77 0-0.381-0.015-1.642-0.022-2.979-4.452 0.968-5.391-1.888-5.391-1.888-0.728-1.849-1.776-2.341-1.776-2.341-1.452-0.993 0.11-0.973 0.11-0.973 1.606 0.113 2.452 1.649 2.452 1.649 1.427 2.446 3.743 1.739 4.656 1.33 0.143-1.034 0.558-1.74 1.016-2.14-3.554-0.404-7.29-1.777-7.29-7.907 0-1.747 0.625-3.174 1.649-4.295-0.166-0.403-0.714-2.030 0.155-4.234 0 0 1.344-0.43 4.401 1.64 1.276-0.355 2.645-0.532 4.005-0.539 1.359 0.006 2.729 0.184 4.008 0.539 3.054-2.070 4.395-1.64 4.395-1.64 0.871 2.204 0.323 3.831 0.157 4.234 1.026 1.12 1.647 2.548 1.647 4.295 0 6.145-3.743 7.498-7.306 7.895 0.574 0.497 1.085 1.47 1.085 2.963 0 2.141-0.019 3.864-0.019 4.391 0 0.426 0.288 0.925 1.099 0.768 6.354-2.118 10.933-8.113 10.933-15.18 0-8.837-7.164-16-16-16z" />
  </svg>
);
